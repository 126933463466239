.card {
  padding: 1rem;
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hover-lift:hover {
  transform: translateY(-2px);
  transition: transform 0.2s ease;
}
