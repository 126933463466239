.data-table-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.data-table-search {
  width: 100%;
  max-width: 300px;
}

.data-table-container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: white;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.data-table th,
.data-table td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e5e7eb;
}

.data-table th {
  background-color: #f9fafb;
  font-weight: 600;
  color: #374151;
  white-space: nowrap;
}

.data-table td {
  color: #4b5563;
}

.sticky-header thead tr {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f9fafb;
}

.data-table th.sortable {
  cursor: pointer;
  user-select: none;
}

.data-table th.sortable:hover {
  background-color: #f3f4f6;
}

.sort-indicator {
  margin-left: 0.5rem;
  color: #6b7280;
}

.data-table tbody tr:hover {
  background-color: #f9fafb;
}

.data-table tbody tr.selected {
  background-color: #e5e7eb;
}

.data-table tbody tr.clickable {
  cursor: pointer;
}

.data-table-loading,
.data-table-empty {
  text-align: center;
  padding: 2rem !important;
  color: #6b7280;
}

.data-table-pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .data-table-container {
    border-color: #374151;
    background-color: #1f2937;
  }

  .data-table th {
    background-color: #111827;
    color: #e5e7eb;
  }

  .data-table td {
    color: #d1d5db;
  }

  .data-table th,
  .data-table td {
    border-bottom-color: #374151;
  }

  .sticky-header thead tr {
    background-color: #111827;
  }

  .data-table th.sortable:hover {
    background-color: #1f2937;
  }

  .sort-indicator {
    color: #9ca3af;
  }

  .data-table tbody tr:hover {
    background-color: #1f2937;
  }

  .data-table tbody tr.selected {
    background-color: #374151;
  }

  .data-table-loading,
  .data-table-empty {
    color: #9ca3af;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .data-table th,
  .data-table td {
    padding: 0.5rem 0.75rem;
  }

  .data-table-search {
    max-width: 100%;
  }
}
