.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.pagination-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 0.5rem;
}

.pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  height: 2.5rem;
  padding: 0 0.75rem;
  border: 1px solid #ddd;
  background-color: white;
  color: #333;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f5f5f5;
  border-color: #ccc;
}

.pagination-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.pagination-button.active {
  background-color: #000;
  border-color: #000;
  color: white;
}

.pagination-button:disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #999;
  cursor: not-allowed;
}

.pagination-ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  height: 2.5rem;
  color: #666;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .pagination-button {
    background-color: #1a1a1a;
    border-color: #333;
    color: #fff;
  }

  .pagination-button:hover:not(:disabled) {
    background-color: #333;
    border-color: #444;
  }

  .pagination-button:focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  }

  .pagination-button.active {
    background-color: #fff;
    border-color: #fff;
    color: #000;
  }

  .pagination-button:disabled {
    background-color: #222;
    border-color: #333;
    color: #666;
  }

  .pagination-ellipsis {
    color: #999;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .pagination-button {
    min-width: 2rem;
    height: 2rem;
    padding: 0 0.5rem;
    font-size: 0.8125rem;
  }

  .pagination-list {
    gap: 0.25rem;
  }
}
