.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabs-list {
  display: flex;
  gap: 0.25rem;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1rem;
}

.tabs-center .tabs-list {
  justify-content: center;
}

.tabs-end .tabs-list {
  justify-content: flex-end;
}

.tabs-full-width .tabs-list {
  gap: 0;
}

.tabs-full-width .tab-button {
  flex: 1;
}

/* Default variant */
.tabs-default .tab-button {
  padding: 0.75rem 1.25rem;
  border: none;
  background: none;
  color: #6b7280;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.2s;
}

.tabs-default .tab-button:hover {
  color: #111827;
}

.tabs-default .tab-button.active {
  color: #111827;
  border-bottom: 2px solid #111827;
  margin-bottom: -1px;
}

/* Pills variant */
.tabs-pills .tab-button {
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-radius: 9999px;
  background: none;
  color: #6b7280;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.tabs-pills .tab-button:hover {
  background-color: #f3f4f6;
}

.tabs-pills .tab-button.active {
  background-color: #111827;
  color: white;
}

/* Underline variant */
.tabs-underline .tab-button {
  padding: 0.75rem 1.25rem;
  border: none;
  background: none;
  color: #6b7280;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
}

.tabs-underline .tab-button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #111827;
  transform: scaleX(0);
  transition: transform 0.2s;
}

.tabs-underline .tab-button:hover::after {
  transform: scaleX(0.5);
}

.tabs-underline .tab-button.active::after {
  transform: scaleX(1);
}

/* Disabled state */
.tab-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Tab panels */
.tab-panel {
  outline: none;
}

.tab-panel:focus-visible {
  outline: 2px solid #111827;
  outline-offset: 2px;
  border-radius: 0.25rem;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .tabs-list {
    border-bottom-color: #374151;
  }

  .tabs-default .tab-button {
    color: #9ca3af;
  }

  .tabs-default .tab-button:hover {
    color: #e5e7eb;
  }

  .tabs-default .tab-button.active {
    color: #e5e7eb;
    border-bottom-color: #e5e7eb;
  }

  .tabs-pills .tab-button:hover {
    background-color: #374151;
  }

  .tabs-pills .tab-button.active {
    background-color: #e5e7eb;
    color: #111827;
  }

  .tabs-underline .tab-button {
    color: #9ca3af;
  }

  .tabs-underline .tab-button::after {
    background-color: #e5e7eb;
  }

  .tab-panel:focus-visible {
    outline-color: #e5e7eb;
  }
}

/* Mobile styles */
@media (max-width: 640px) {
  .tabs-list {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .tabs-list::-webkit-scrollbar {
    display: none;
  }

  .tab-button {
    white-space: nowrap;
  }
}
