.search-container {
  position: relative;
  width: 100%;
  max-width: 500px;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input {
  width: 100%;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background-color: white;
}

.search-input:focus {
  outline: none;
  border-color: #000;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.search-spinner {
  position: absolute;
  right: 1rem;
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.search-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
}

.search-suggestion {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.search-suggestion:hover,
.search-suggestion:focus {
  background-color: #f5f5f5;
  outline: none;
}

.search-suggestion[aria-selected="true"] {
  background-color: #f0f0f0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .search-input {
    background-color: #1a1a1a;
    border-color: #333;
    color: white;
  }

  .search-input:focus {
    border-color: #666;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
  }

  .search-suggestions {
    background-color: #1a1a1a;
    border-color: #333;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  .search-suggestion:hover,
  .search-suggestion:focus {
    background-color: #333;
  }

  .search-suggestion[aria-selected="true"] {
    background-color: #404040;
  }

  .search-spinner {
    border-color: #333;
    border-top-color: #fff;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .search-input {
    font-size: 16px; /* Prevent zoom on iOS */
    padding: 0.5rem 2rem 0.5rem 0.75rem;
  }

  .search-suggestions {
    max-height: 50vh;
  }
}
