.banner {
  background-color: #000000;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.banner-content {
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.banner-content h1 {
  font-size: 0.875rem;
  margin: 0;
  font-weight: 500;
  color: white;
}

/* Mobile styles */
@media (max-width: 768px) {
  .banner {
    height: 30px;
  }

  .banner-content h1 {
    font-size: 0.75rem;
  }
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .banner {
    height: 35px;
  }

  .banner-content h1 {
    font-size: 0.8125rem;
  }
}
