.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.label {
  font-weight: 500;
  color: #333;
  font-size: 0.875rem;
}

.required {
  color: #dc3545;
  margin-left: 0.25rem;
}

.input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s ease;
  width: 100%;
  background-color: white;
}

.input:focus {
  outline: none;
  border-color: #000;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.input.error {
  border-color: #dc3545;
}

.input.error:focus {
  box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.1);
}

.errorText {
  color: #dc3545;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

textarea.input {
  resize: vertical;
  min-height: 100px;
}

.input:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}
