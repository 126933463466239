/* App-specific styles */
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Navigation styles */
.nav-links {
  list-style: none;
  display: flex;
  gap: 30px;
}

.nav-links li {
  cursor: pointer;
  font-weight: 500;
  color: #000000;
  transition: color 0.3s ease;
}

.nav-links li:hover {
  color: #333;
}

/* Main content area */
.main-content {
  flex: 1;
  padding: 20px 0;
}

/* Footer positioning */
.footer {
  margin-top: auto;
}
