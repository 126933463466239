.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.95);
  padding: 1rem 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 80px;
  display: flex;
  align-items: center;
}

.navbar-content {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 40px;
}

.logo-container img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2.5rem;
  margin: 0;
  margin-left: 4rem;
  padding: 0;
  align-items: center;
  height: 100%;
}

.nav-links li {
  cursor: pointer;
  font-weight: 500;
  font-size: 0.95rem;
  color: #000000;
  transition: color 0.2s ease;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-links li:hover {
  color: #333;
}

.nav-links a {
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
}

.client-login {
  background: black;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.95rem;
  text-decoration: none;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  margin-left: auto;
  height: 40px;
  display: flex;
  align-items: center;
}

.client-login:hover {
  background-color: #333;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

/* Mobile styles */
@media (max-width: 768px) {
  .navbar {
    height: 60px;
    padding: 0 1rem;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    flex-direction: column;
    padding: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-left: 0;
    height: auto;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li {
    height: 40px;
  }

  .menu-toggle {
    display: block;
  }

  .client-login {
    display: none;
  }

  .client-login.active {
    display: flex;
  }
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .nav-links {
    gap: 1.5rem;
    margin-left: 2rem;
  }
}
