.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 1rem;
}

.modal {
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column;
  outline: none;
}

.modal-small {
  width: 100%;
  max-width: 400px;
}

.modal-medium {
  width: 100%;
  max-width: 600px;
}

.modal-large {
  width: 100%;
  max-width: 800px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #eee;
}

.modal-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.25rem;
  cursor: pointer;
  color: #666;
  transition: color 0.2s;
}

.modal-close:hover {
  color: #333;
}

.modal-content {
  padding: 1.5rem;
  overflow-y: auto;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .modal {
    background-color: #222;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }

  .modal-header {
    border-bottom-color: #333;
  }

  .modal-title {
    color: #fff;
  }

  .modal-close {
    color: #999;
  }

  .modal-close:hover {
    color: #fff;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .modal-container {
    padding: 0.5rem;
  }

  .modal {
    max-height: calc(100vh - 1rem);
  }

  .modal-header {
    padding: 0.75rem 1rem;
  }

  .modal-content {
    padding: 1rem;
  }
}
