.error-boundary {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: 2rem;
  text-align: center;
  background-color: #f8f9fa;
}

.error-content {
  max-width: 600px;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.error-content h2 {
  margin: 0 0 1rem;
  color: #dc3545;
  font-size: 1.5rem;
}

.error-content p {
  margin: 0 0 1.5rem;
  color: #6c757d;
}

.error-details {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  text-align: left;
}

.error-details summary {
  cursor: pointer;
  color: #6c757d;
  font-weight: 500;
}

.error-details pre {
  margin: 1rem 0 0;
  padding: 1rem;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow-x: auto;
  font-size: 0.875rem;
  line-height: 1.5;
}

.error-reset-button {
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #000;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.error-reset-button:hover {
  background-color: #333;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .error-boundary {
    background-color: #1a1a1a;
  }

  .error-content {
    background-color: #222;
  }

  .error-content h2 {
    color: #ff6b6b;
  }

  .error-content p {
    color: #adb5bd;
  }

  .error-details {
    background-color: #2a2a2a;
  }

  .error-details summary {
    color: #adb5bd;
  }

  .error-details pre {
    background-color: #333;
    color: #f8f9fa;
  }

  .error-reset-button {
    background-color: #fff;
    color: #000;
  }

  .error-reset-button:hover {
    background-color: #e6e6e6;
  }
}
