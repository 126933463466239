.form-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-field-label {
  font-weight: 500;
  color: #333;
  font-size: 0.875rem;
}

.form-field-required {
  color: #dc3545;
  margin-left: 0.25rem;
}

.form-field-hint {
  color: #6c757d;
  font-size: 0.75rem;
}

.form-field-error-message {
  color: #dc3545;
  font-size: 0.75rem;
}

.form-field-error input,
.form-field-error textarea,
.form-field-error select {
  border-color: #dc3545;
}

.form-field-error input:focus,
.form-field-error textarea:focus,
.form-field-error select:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .form-field-label {
    color: #e9ecef;
  }

  .form-field-required {
    color: #ff6b6b;
  }

  .form-field-hint {
    color: #adb5bd;
  }

  .form-field-error-message {
    color: #ff6b6b;
  }

  .form-field-error input,
  .form-field-error textarea,
  .form-field-error select {
    border-color: #ff6b6b;
  }

  .form-field-error input:focus,
  .form-field-error textarea:focus,
  .form-field-error select:focus {
    border-color: #ff6b6b;
    box-shadow: 0 0 0 0.2rem rgba(255, 107, 107, 0.25);
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .form-field {
    gap: 0.25rem;
  }
}
