.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.form-row {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.form-col {
  flex: 1;
  min-width: 0;
}

.form-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.form-error {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.form-hint {
  color: #6c757d;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .form-error {
    color: #ff6b6b;
  }

  .form-hint {
    color: #adb5bd;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 1rem;
  }

  .form-col {
    width: 100%;
  }

  .form-actions {
    flex-direction: column;
    gap: 0.5rem;
  }

  .form-actions button {
    width: 100%;
  }
}
