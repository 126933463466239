/* Common styles shared across components */

/* Button styles */
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.btn-primary {
  background-color: black;
  color: white;
}

.btn-secondary {
  background-color: #f5f5f5;
  color: black;
}

.btn-outline {
  background-color: transparent;
  border: 1px solid black;
  color: black;
}

.btn:hover {
  transform: translateY(-1px);
}

.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.btn-small {
  padding: 6px 12px;
  font-size: 0.875rem;
}

.btn-medium {
  padding: 8px 16px;
  font-size: 1rem;
}

.btn-large {
  padding: 12px 24px;
  font-size: 1.125rem;
}

.btn-full-width {
  width: 100%;
}

.btn-loading {
  position: relative;
  color: transparent;
}

.btn-spinner {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Card styles */
.card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
}

.card.hover-lift:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Section styles */
.section {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

.section-content {
  padding: var(--section-padding, 40px 20px);
}

.section-bg-white {
  background-color: white;
}

.section-bg-light {
  background-color: #f5f5f5;
}

.section-bg-dark {
  background-color: #111;
  color: white;
}

.section-spacing-small {
  --section-padding: 20px;
}

.section-spacing-medium {
  --section-padding: 40px;
}

.section-spacing-large {
  --section-padding: 80px;
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.section-subtitle {
  font-size: 1.125rem;
  color: #666;
  margin-bottom: 2rem;
}

/* Container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Text styles */
.text-center {
  text-align: center;
}

.text-primary {
  color: black;
}

/* Spacing utilities */
.mt-1 {
  margin-top: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}
.mb-4 {
  margin-bottom: 2rem;
}

/* Flexbox utilities */
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

/* Common layout styles */
.page-container {
  text-align: center;
  background-color: #fff;
}

.content-container {
  padding: 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.contact-newsletter-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 20px;
  margin: 10px auto;
  max-width: 1200px;
}

.contact-newsletter-container > * {
  flex: 1;
  max-width: 500px;
}

/* Common heading styles */
.page-title {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #000000;
}

/* Common card hover effect */
.hover-lift {
  transition: transform 0.3s ease;
}

.hover-lift:hover {
  transform: translateY(-5px);
}

/* Media queries */
@media (max-width: 768px) {
  .contact-newsletter-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-newsletter-container > * {
    width: 100%;
  }

  .page-title {
    font-size: 2rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .section-spacing-large {
    --section-padding: 40px;
  }
}

/* Loading styles */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

/* Spinner */
.loading-spinner {
  width: var(--spinner-size, 40px);
  height: var(--spinner-size, 40px);
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: black;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-small .loading-spinner {
  --spinner-size: 24px;
}

.loading-large .loading-spinner {
  --spinner-size: 64px;
}

/* Dots */
.loading-dots {
  display: flex;
  gap: 8px;
}

.loading-dots div {
  width: var(--dot-size, 12px);
  height: var(--dot-size, 12px);
  background-color: black;
  border-radius: 50%;
  animation: bounce 0.5s alternate infinite;
}

.loading-dots div:nth-child(2) {
  animation-delay: 0.16s;
}

.loading-dots div:nth-child(3) {
  animation-delay: 0.32s;
}

.loading-small .loading-dots {
  --dot-size: 8px;
}

.loading-large .loading-dots {
  --dot-size: 16px;
}

/* Pulse */
.loading-pulse {
  width: var(--pulse-size, 40px);
  height: var(--pulse-size, 40px);
  background-color: black;
  border-radius: 50%;
  animation: pulse 1.5s ease-in-out infinite;
}

.loading-small .loading-pulse {
  --pulse-size: 24px;
}

.loading-large .loading-pulse {
  --pulse-size: 64px;
}

/* Animations */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  to {
    transform: translateY(-8px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.8);
    opacity: 0.5;
  }
}

/* Screen reader only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
