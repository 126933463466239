.footer {
  background-color: #111;
  color: #fff;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-sections {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-column {
  margin-right: 20px;
}

/* Headings in footer */
.footer-column h3 {
  margin-bottom: 10px;
}

/* List reset */
.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 5px;
}

/* Footer links styling */
.footer-column a {
  text-decoration: none;
  color: #ffffff; /* changed to white for consistency */
  transition: color 0.3s ease;
}

.footer-column a:hover {
  color: #00aaff; /* new hover color */
  text-decoration: underline;
}

.social-icons {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.social-icon {
  font-size: 24px;
  color: #ffffff;
  transition:
    color 0.3s ease,
    transform 0.3s ease;
}

.social-icon:hover {
  color: #00aaff;
  transform: translateY(-2px);
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid #444;
  margin-top: 20px;
}

.footer-links {
  list-style-type: none;
  display: flex;
  gap: 15px;
  padding: 0;
}

.footer-links li {
  cursor: pointer;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .footer-bottom {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Mobile Footer 1*/
@media (max-width: 768px) {
  .footer-sections {
    flex-direction: column;
  }

  .footer-column {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-links {
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
}

/* Tablet Footer 1*/
@media (min-width: 769px) and (max-width: 1024px) {
  .footer-sections {
    flex-direction: column;
  }

  .footer-column {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-links {
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }
}
