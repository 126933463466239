.toast-container {
  position: fixed;
  z-index: 1100;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  pointer-events: none;
}

.toast-container-top-right {
  top: 0;
  right: 0;
}

.toast-container-top-left {
  top: 0;
  left: 0;
}

.toast-container-bottom-right {
  bottom: 0;
  right: 0;
}

.toast-container-bottom-left {
  bottom: 0;
  left: 0;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 15px 20px;
  border-radius: 4px;
  opacity: 0.9;
  transition: opacity 0.3s ease;
  z-index: 10000;
}

.toast-success {
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.toast-error {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

.toast-warning {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
}

.toast-info {
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  color: #004085;
}

.toast-icon {
  font-size: 1.25rem;
  line-height: 1;
}

.toast-message {
  margin: 0;
  flex-grow: 1;
  font-size: 0.875rem;
}

.toast-close {
  background: none;
  border: none;
  padding: 0;
  font-size: 1.25rem;
  line-height: 1;
  opacity: 0.5;
  cursor: pointer;
  transition: opacity 0.2s;
}

.toast-close:hover {
  opacity: 1;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .toast {
    background-color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  .toast-success {
    background-color: #1b4332;
    border-color: #2d6a4f;
    color: #b7e4c7;
  }

  .toast-error {
    background-color: #7f1d1d;
    border-color: #991b1b;
    color: #fecaca;
  }

  .toast-warning {
    background-color: #78350f;
    border-color: #92400e;
    color: #fcd34d;
  }

  .toast-info {
    background-color: #1e3a8a;
    border-color: #1e40af;
    color: #bfdbfe;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .toast-container {
    padding: 0.5rem;
  }

  .toast {
    min-width: auto;
    max-width: calc(100vw - 2rem);
  }
}
